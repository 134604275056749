<template>


    <layout-admin-dashboard-view
            :showToolbar="false"
            :backRoute="{name: 'admin-user'}">
        <template #title>
            {{translateTitleCase('entity.crud.createEntityTitle')}} <strong>{{translateTitleCase('core.user.user')}}</strong>
        </template>


        <template #default>
            <crud-create
                    :formPath="formPath"
                    :formAction="formAction"
                    :successRedirect="{'name': 'admin-user'}"
                    >

            </crud-create>

        </template>
    </layout-admin-dashboard-view>



</template>

<script>
    import CrudCreate from '@/client/components/crud/Create.vue';


    import { useMeta } from 'vue-meta'
    import {useI18n} from "vue-i18n/index";


    export default {
        setup (props, setupContext ) {
            let {t} = useI18n();
            let {meta} = useMeta( {
                'title': t('admin.user.createView.title'),
            });

            return {meta}
        },
        props: {
            entityType: {
                type: String,
                default: 'default'
            }
        },
        name: 'entity-create',
        components: {CrudCreate},
        data: function () {
            return {
                entity: {}
            };
        },
        computed: {
            formPath () {
                return 'form/user.user'
            },
            formAction () {
                return 'user'
            }
        },
        methods: {

        }

    }
</script>

<style scoped lang="scss">

</style>
